import { createQueryString } from '@sixfold/query-string';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getTableHeaderClassNames, emptyTableState } from '../../components/table';
import { capitalize } from '../../lib/format';
import { Routes } from '../../routes';

export interface CompanyMetricRow {
  company: Company | null;
  transport_count: number;
  allocated_count: number;
  percent_allocated: string;
  visibility_index: string;
}

interface Company {
  company_id: string;
  company_name: string | null;
}

interface ParentCompany {
  company_id: string;
}

export interface MetricsViewDataProps {
  company: ParentCompany | null;
  rows: CompanyMetricRow[];
}

export interface MetricsViewProps {
  show: 'carriers' | 'customers';
}

class MetricsTable extends Table<CompanyMetricRow> {}

const getPartnershipIds = (company: ParentCompany, partner: Company | null, show: 'carriers' | 'customers') => ({
  carrier_id: show === 'customers' ? company.company_id : partner !== null && partner.company_id,
  shipper_id: show === 'customers' ? partner !== null && partner.company_id : company.company_id,
});

export const MetricsView: React.FC<MetricsViewDataProps & MetricsViewProps> = ({ rows, company, show }) => {
  return (
    <div>
      <h2 className="table__header">
        <span>{capitalize(show)}</span>
      </h2>
      <MetricsTable
        className="ui very basic padded sortable unstackable table"
        data={rows}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { keyPath: 'company.company_name', value: 'Company' },
            { keyPath: 'transport_count', value: 'Transports' },
            { keyPath: 'allocated_count', value: 'With vehicle allocated' },
            { keyPath: 'percent_allocated', value: '% Allocated' },
            { keyPath: 'visibility_index', value: 'Visibility index' },
          ],
        }}
        defaultSortBy={{ keyPath: 'transport_count', value: 'ASC' }}
        emptyStatePlaceholder={emptyTableState(show === 'carriers' ? 'No carriers' : 'No customers')}>
        {({ row }) => {
          const { data, index } = row;

          if (company === null) {
            return;
          }

          const { carrier_id, shipper_id } = getPartnershipIds(company, data.company, show);
          const filterQuery = createQueryString({
            carrier_id,
            shipper_id,
          });

          const companyName = data?.company?.company_name;
          const companyId = data?.company?.company_id;
          return (
            <tr key={index}>
              <td>
                {notNil(companyName) && notNil(companyId) ? (
                  <NavLink to={Routes.Company.generatePath({ company_id: companyId })}>{companyName}</NavLink>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <NavLink to={`${Routes.Tours.generatePath({})}?${filterQuery}`}>{data.transport_count}</NavLink>
              </td>
              <td>
                <NavLink to={`${Routes.Tours.generatePath({})}?${filterQuery}&status=allocated`}>
                  {data.allocated_count}
                </NavLink>
              </td>
              <td>{data.percent_allocated}</td>
              <td>{data.visibility_index}</td>
            </tr>
          );
        }}
      </MetricsTable>
    </div>
  );
};
