import { Loader } from '@sixfold/loader-container';
import { deduplicateLastByKey } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import {
  companyCarrierToursByShipperMetricsQuery,
  companyShipperToursByCarrierMetricsQuery,
} from '../../company/graphql';
import { InfiniteScroll } from '../../components/infinitescroll';
import {
  CompanyCarrierToursByShippersMetricsQuery,
  CompanyCarrierToursByShippersMetricsQueryVariables,
  CompanyShipperToursByCarriersMetricsQuery,
  CompanyShipperToursByCarriersMetricsQueryVariables,
  getNodes,
  loadMoreFromConnection,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { indexValueToPercentage } from '../../lib/util/string';
import { MetricsView, MetricsViewDataProps } from '../components/metrics';

interface Company {
  company_id: string;
  company_name: string | null;
}

interface MetricsContainerProps extends RouteComponentProps<{ company_id: string }> {
  show: 'carriers' | 'customers';
}

class LoadingMetricsByCarriersContainer extends Loader<
  CompanyShipperToursByCarriersMetricsQuery,
  MetricsViewDataProps,
  CompanyShipperToursByCarriersMetricsQueryVariables
> {}

class LoadingMetricsByShippersContainer extends Loader<
  CompanyCarrierToursByShippersMetricsQuery,
  MetricsViewDataProps,
  CompanyCarrierToursByShippersMetricsQueryVariables
> {}

class MetricsContainerCompanyCarrierToursByShippersMetricsQuery extends Query<
  CompanyCarrierToursByShippersMetricsQuery,
  CompanyCarrierToursByShippersMetricsQueryVariables
> {}

class MetricsContainerCompanyShipperToursByCarriersMetricsQuery extends Query<
  CompanyShipperToursByCarriersMetricsQuery,
  CompanyShipperToursByCarriersMetricsQueryVariables
> {}

const mapRowData = (
  company: Company | null,
  data: { visibilityIndex: number | null; toursCount: number; allocatedToursCount: number },
) => ({
  company,
  transport_count: data.toursCount,
  allocated_count: data.allocatedToursCount,
  visibility_index: indexValueToPercentage(data.visibilityIndex),
  percent_allocated: (data.toursCount !== 0 ? (data.allocatedToursCount / data.toursCount) * 100 : 0).toFixed(2),
});

export class MetricsContainer extends React.Component<MetricsContainerProps> {
  mapMetricsData = ({
    data,
  }: {
    data: Partial<CompanyCarrierToursByShippersMetricsQuery | CompanyShipperToursByCarriersMetricsQuery>;
  }) => {
    const company = data.company;

    if (company === undefined || company === null) {
      return { rows: [], company: null };
    }

    if ('tourReportsByShipper' in company) {
      return {
        rows: getNodes(company.tourReportsByShipper).map((row) => mapRowData(row.shipper, row)),
        company,
      };
    }

    return { rows: getNodes(company.tourReportsByCarrier).map((row) => mapRowData(row.carrier, row)), company };
  };

  render() {
    const { match, show } = this.props;

    const metricsVariables = {
      company_id: match.params.company_id,
      first: PAGINATION_PAGE_SIZE,
    };

    if (show === 'carriers') {
      return (
        <MetricsContainerCompanyShipperToursByCarriersMetricsQuery
          query={companyShipperToursByCarrierMetricsQuery}
          variables={metricsVariables}>
          {(result) => (
            <LoadingMetricsByCarriersContainer result={result} mapData={this.mapMetricsData}>
              {({ rows, company }) => {
                const deduplicatedRows = deduplicateLastByKey(rows, ({ company }) => company?.company_id ?? '');
                return (
                  <InfiniteScroll
                    loadMoreEntries={() =>
                      loadMoreFromConnection(companyShipperToursByCarrierMetricsQuery, result, [
                        'company',
                        'tourReportsByCarrier',
                      ])
                    }>
                    <MetricsView rows={deduplicatedRows} company={company} show={show} />
                  </InfiniteScroll>
                );
              }}
            </LoadingMetricsByCarriersContainer>
          )}
        </MetricsContainerCompanyShipperToursByCarriersMetricsQuery>
      );
    }

    return (
      <MetricsContainerCompanyCarrierToursByShippersMetricsQuery
        query={companyCarrierToursByShipperMetricsQuery}
        variables={metricsVariables}>
        {(result) => (
          <LoadingMetricsByShippersContainer result={result} mapData={this.mapMetricsData}>
            {({ rows, company }) => {
              const deduplicatedRows = deduplicateLastByKey(rows, ({ company }) => company?.company_id ?? '');
              return (
                <InfiniteScroll
                  loadMoreEntries={() =>
                    loadMoreFromConnection(companyCarrierToursByShipperMetricsQuery, result, [
                      'company',
                      'tourReportsByShipper',
                    ])
                  }>
                  <MetricsView rows={deduplicatedRows} company={company} show={show} />
                </InfiniteScroll>
              );
            }}
          </LoadingMetricsByShippersContainer>
        )}
      </MetricsContainerCompanyCarrierToursByShippersMetricsQuery>
    );
  }
}
